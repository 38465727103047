import commonHelper from '@/app/utility/common.helper.utility';
import addEditUserLocationMapping from './addEditUserLocationMapping';

export default {
  name: 'userLocationMapping',
  components: {addEditUserLocationMapping},
  watch: {
    currentPage: function() {
      this.getUserLocationData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUserLocationData();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      showValueSetModal: false,
      userLocationData: [],
      userLocationFields: [
        {
          key: 'user_name',
          label: 'Employee Name'
        },
        {
          key: 'email',
          label: 'Employee Email'
        },
        {
          key: 'location_name'
        },
        {
          key:'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      showAddEditUserLocationModalFlag: false,
      emailId:null
    };
  },
  mounted() {
    this.getUserLocationData()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
            this.showAddEditUserLocationModal(true);
        } else if (actionName === 'update' || actionName === 'edit') {
          this.editMode = true;
        } else if (actionName === 'save') {
          //   this.addEditLocation();
        }
      }
    });
  },
  methods: {
    getUserLocationData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        email:this.emailId,
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getUserLocationData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.userLocationData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearUser() {
      this.emailId = null;
    },
    showAddEditUserLocationModal(flag) {
      this.showAddEditUserLocationModalFlag = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
