// import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import user from "../../../admin/user"
export default {
  name: 'addEditUserLocationMapping',
  components: {user},
  watch: {
    // currentPage: function() {
    //   this.getUserLocationData();
    // },
    // perPage: function() {
    //   this.currentPage = 1;
    //   this.getUserLocationData();
    // }
    tags() {
        this.adjustTagsWidth();
      }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      showValueSetModal: false,
      emailId:null,
      employeeId:null,
      showHideEmployeeDetailsModalFlag:false,
      accessButtons:{},
      locations:[],
      locationsId:[],
      active:false,
      showHideAlertModalFlag:false,
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
            this.postUserLocationData();
        } else if(actionName === 'delete'){
          // this.showHideAlertModal(true)
          this.deleteDataLoc()
        }
      }
    });
  },
  methods: {
    showHideEmployeeDetailsModal(flag,item){
        this.showHideEmployeeDetailsModalFlag=flag
        if(!flag && item){
          this.employeeId=item.user_id
          this.emailId=item.user_email
          this.locations=[]
          this.locationsId=[]
          this.getUserLocationMappedData()
        }
          this.accessButtons = {
            deleteAllow: false,
            downloadAllow: false,
            uploadAllow: false,
            editAllow: false,
            addAllow: false,
            printAllow: false,
            approvalAllow: false
        }
      },
      removeTags(tags, tag) {
        this.loader=true
        const index = tags.indexOf(tag);
        if (index !== -1) {
          tags.splice(index, 1);
        }
        this.loader=false
        this.deleteSingleUserLocationData(index);
        this.locationsId.splice(index,1)
      },
  
      adjustTagsWidth() {
        const tagsWidth = this.$refs.tags.$el.scrollWidth;
        this.$refs.tags.$el.style.width = `${tagsWidth}px`;
      },
      openValueSetModal(vsetCode) {
        this.vsetCode = vsetCode;
        this.showValueSetModal = true;
        this.setTimeVsetCode = setTimeout(() => {
          const vSetData = {
            valueSetName: vsetCode,
            multiFlag: null
          };
          this.eventBus.$emit('valueSetCode', vSetData);
        }, 0);
      },
      selectedvalueSet(item) {
     if(this.vsetCode === appStrings.VALUESETTYPE.LOCATION){
          this.locations.push(item.value_code)
          this.locationsId.push({
                org_location_id:item.loc_id
          })
        }
      },
      closeValueSetModal() {
        this.showValueSetModal = false;
      },
      saveUerLocation(){

      },
    postUserLocationData() {
      let locsId = []
       this.locationsId.map(ele=>{
        if(ele.org_location_id){
          locsId.push({
            org_location_id:ele.org_location_id
          })
        }
     
      })
      const payload = {
        user_id: this.employeeId,
        location_mapping_id: 0,
        location_id: locsId,
        active_key:this.active,
      };
      if(payload.user_id==null){
        this.$bvToast.toast("Select Employee Details", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
      } else if(payload.location_id.length==0 && !this.active){
        this.$bvToast.toast("Select Location", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
      }
      else{
        this.loader = true;
        this.$store
          .dispatch('assets/postUserLocationMapping', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              // this.isSuccess = true;
              this.responseMsg=resp.data.message;
              // this.showAlert = true;
              this.$bvToast.toast(this.responseMsg, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
          }else{
            this.responseMsg=resp.response.data.message;
            this.$bvToast.toast(this.responseMsg, {
              title: 'Alert',
              variant: 'warning',
              solid: true
            });
          }
          })
          .catch(() => {
            this.loader = false;
              });
      }
    },
    getUserLocationMappedData() {
        const payload = {
          userId:this.employeeId,
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getUserLocationMappedData', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              resp.data.data.map(ele=>{
                this.locations.push(ele.location_name)
                this.locationsId.push(ele.location_id)
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },    
      clearVsetValues(value){
        if(value=="EMAILID"){
            this.employeeId=null
            this.emailId=null
            this.locations=[]
            this.locationsId=[]
        }
      },
      showHideAlertModal(flag,item){
        this.showHideAlertModalFlag=flag
        this.getData=item
      },
      deleteUserLocationData() {
        const payload = {
          userId:this.employeeId,
        };
        this.loader = true;
        this.$store
          .dispatch('assets/deleteAllLocationsData', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              // this.isSuccess = true;
              this.responseMsg=resp.data.message;
              // this.showAlert = true;
              this.$bvToast.toast(this.responseMsg, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
              this.showHideAlertModal(false)
              this.getUserLocationMappedData()
            }else{
              this.responseMsg=resp.data.message;
              this.$bvToast.toast(this.responseMsg, {
                title: 'Alert',
                variant: 'warning',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      deleteDataLoc(){
        if(this.employeeId){
          this.showHideAlertModal(true)
        }else{
          this.$bvToast.toast("Kindly select an employee", {
            title: 'Alert',
            variant: 'danger',
            solid: true,
          });
        }
      },
      deleteSingleUserLocationData(index) {
        const payload = {
          userId:this.employeeId,
          locationId:this.locationsId[index]
        };
        this.loader = true;
        this.$store
          .dispatch('assets/deleteSinglelLocationsData', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              // this.isSuccess = true;
              this.responseMsg=resp.data.message;
              // this.showAlert = true;
              this.$bvToast.toast(this.responseMsg, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
              this.getUserLocationMappedData()
            }else{
              this.responseMsg=resp.data.message;
              this.$bvToast.toast(this.responseMsg, {
                title: 'Alert',
                variant: 'warning',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      // removeLocations(status){
      //   if(status){
      //     this.locations=[],
      //     this.locationsId=[]
      //   }
      // },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
